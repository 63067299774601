import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useMobileFontOverrides} from '../../../../../commons/hooks/use-mobile-font-overrides'
import {useSettings} from '../../../hooks/use-settings'
import settingsParams from '../../../settingsParams'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import sc from '../classes.scss'
import s from './main-list-title.scss'
import {MainListTitleProps} from '.'

export const MainListTitle = ({listLayout, mobile, cardsLayout}: MainListTitleProps) => {
  const {get} = useSettings()
  const title = get(settingsParams.listGeneralTitle)
  const {allBreakpoints} = useVisibilityStyles()
  const visible = allBreakpoints.isListMainTitleEnabled()
  const {mobileFontOverridesEnabled} = useMobileFontOverrides()

  if (!visible) {
    return null
  }

  return (
    <h1
      className={classNames(s.title, sc.textLineHeight, {
        [s.mobile]: mobile,
        [s.cards]: cardsLayout,
        [s.listLayout]: listLayout,
        [s.defaultLayout]: !mobile && !listLayout && !cardsLayout,
        [s.mobileFontSize]: mobile && mobileFontOverridesEnabled,
      })}
      data-hook={DH.listTitle}
    >
      {title}
    </h1>
  )
}
